
.logo-wrapper{
    /* background-color: #fff; */
    padding: 10px 0;
}

.companyLogo{
    height: 45px;
    width: 120px;
    margin: 10px 0 2px 20px;
}

@media only screen and (min-width: 600px) {
    .companyLogo{
        height: 50px;
    }
}