.aboutContainer {
  /* background-image: url(/public/images/aboutvector.png); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0;
  padding: 50px;
  /* background-color:  rgb(21 94 117); */
  color: black;

}

.aboutTitle {
  margin-bottom: 15px;
}

.aboutTitle h1 {
  font-size: 30px;
  font-weight: 700;
  font-weight: 700;
  color: #053e6c;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: 3px;
}

.aboutBtns button {
  border: 1px solid rgb(125 211 252);
  font-size: 15px;
  /* padding: 10px; */
  background-color: aliceblue;
  color: #053e6c;
  padding: 15px 20px;
}

.activeBtn {
  color: white !important;
    background-color: #053e6c !important ;
}

@media only screen and (min-width: 768px) {

  .aboutContainer {
    width: 100vw;
    /* background-color:  rgb(21 94 117); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: azure;
/* margin-top: 100px; */

  }

  .aboutTitle {
    margin-top: 100px;
    margin-bottom: 20px;
    font-weight: 700;
    color: white;
    text-transform: uppercase;
    line-height: 1em;
    letter-spacing: 3px;
  }

  .aboutTitle h1 {
    font-size: 40px;
    font-weight: 800;
  }

  .aboutBtns button {
    border: 1px solid rgb(125 211 252);
    color: #053e6c;
    border-radius: 3%;
    font-size: 15px;
    padding: 15px 35px;
    background-color: aliceblue;
    text-transform: uppercase;
  }

  .activeBtn {
    color: white !important;
    background-color: #053e6c !important ;
    /* text-decoration: underline; */
  }
}
