.content{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.mainVideo{
    width: 100%;
    height: 100vh;
}

video{
    width: 100%;
    height: 100%;
    object-fit: cover ;
}

.content h1{
    font-size: 45px;
    font-weight: 700;
    /* padding-bottom: 15px; */
}

.content h3{
    font-size: 25px;
    font-weight: 400;
}

.content h1{
    font-size: 55px;
    margin: 0;
}

.content h3{
    font-size: 35px;
    margin: 10px;
}

.home-Btn{
    font-size: 21px;
    color: #fff;
    margin-top: 15px;
    padding: 10px 35px;
    border: 2px solid var(--mainColor);
    /* background-color: var(--mainColor); */
}

.home-Btn:hover{
    background-color: var(--mainColor);
}

/* .content h1 {
    font-size: 2em;
} */

@media only screen and (min-width: 768px) {
    .mainVideo{
        width: 100%;
        height: 100vh;
    }
    
    video{
        width: 100%;
        height: 100%;
        object-fit: cover ;
    }
    
    .content{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .content h1{
        text-transform: uppercase;
        font-size: 80px;
        margin: 10px 0;
    }
    
    .content h3{
        margin: 15px 0;
    }
    
    .overLay{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
    
    }

    
}