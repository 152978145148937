.navBar {
  background-color: var(--mainColor);
  position: fixed;
  z-index: 100;
  box-shadow: rgba(250, 249, 249, 0.35)x 1.95px 2.6px;
}

.navContainer {
  width: 100vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.logo {
  width: auto;
  height: auto;
  padding-bottom: 4px;
}

.navMenu-icon {
  display: none;
}

.navElement ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.navElement ul li {
  text-transform: uppercase;
  margin-right: 60px;
}

.navElement ul a {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  text-decoration: none;
}

.navElement ul a.active {
color: rgb(227, 187, 173);
  font-weight: 500;
  position: relative;
}

.navElement ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: rgb(238, 205, 192);
}


@media (max-width: 600px) {
  .navBar {
    background-color: var(--mainColor);
    top: 100;
    position: fixed;
  }
  .navMenu-icon {
    display: block;
    cursor: pointer;
    padding-right: 25px;
    z-index: 101;
    color: #fff;
    margin-right: 5px;
  }

  .navElement {
    position: absolute;
    right: 0;
    top: 0px;
    background-color: var(--mainColor);
    width: 0px;
    height: 100vh;
    transition: all 0.3s ease-in;
    overflow: hidden;
    z-index: 100;
  }

  .navElement.active {
    width: 270px;
  }

  .navElement ul {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 70px;
  }

  .navElement ul li {
    margin-right: 10px;
    margin-top: 22px;
  }
}
