.contact-container {
  height: auto;
  width: 100%;
}

.wrapper {
  width: 100%;
  margin: 0 auto;
  /* padding: 0 6%; */
}

.contact-header {
  padding-top: 65px;
  padding-bottom: 50px;
}

.contact-header-image img {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.contact-header-text {
  /* background-color: aqua; */
  width: 100%;
}

.contact-header-text h1 {
  line-height: 1.1;
  letter-spacing: 2px;
  font-weight: 600;
  font-size: 33px;
  color: var(--mainColor);
  text-align: center;
  padding-top: 50px;
  text-transform: uppercase;
}

.contact-header-text h4 {
  color: var(--mainColor);
  font-size: 18px;
  font-weight: 400;
  padding-top: 10px;
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 60px;
  text-align: justify;
}

hr {
  width: 92%;
  margin: 0 auto;
}

@media only screen and (min-width: 768px) {
  .contact-header-image {
    padding-top: 35px;
  }

  .contact-header-image img {
    height: 400px;
    object-fit: cover;
  }

  .contact-header-text {
    width: 70%;
    background-color: #fff;
    position: absolute;
    bottom: -20px;
    left: 15%;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  }
  .contact-header-text h1 {
    padding-top: 40px;
  }

  .contact-header-text h4 {
    font-weight: 400;
    /* padding-top: 10px; */
    text-align: center;
    padding: 10px 200px;
  }

  hr {
    width: 100%;
    color: rgb(32, 32, 32);
    height: 2px;
    /* margin: 10px auto; */
    margin-top: 10px;
  }
}

/* ------------------ Contact Body--------------- */

.contact-body {
  padding: 0 30px;
}

.contact-body-text {
    color: var(--mainColor);
}
.contact-body-text h2{
  font-size: 20px;
  font-weight: bold;
}
.contact-body-text h4 {
    font-size: 15px;
}

.contact-body-input-left{
    padding-top: 30px;
}

input[type=text], select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid var(--mainColor);
    border-radius: 4px;
    box-sizing: border-box;
  }

  input[type=submit] {
    width: 100%;
    background-color: var(--mainColor);
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  textarea {
    width: 100%;
    height: 150px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid var(--mainColor);
    border-radius: 4px;
    background-color: #fff;
    /* resize: none; */
  }


  .contact-body-input-right{
    padding-top: 20px;
}

.contact-body-input-left h1{
  font-size: 20px;
  font-weight: bold;
  color: var(--mainColor);
}


.contact-body-input-right h1{
  font-size: 20px;
  font-weight: bold;
  color: var(--mainColor);
  /* padding-bottom: 10px; */
  /* margin-bottom: 20px; */
}

@media only screen and (min-width:968px){
  .contact-body-input-left{
    padding-top: 30px;
    width: 50%;
}
.contact-body-input-right{
  /* padding-top: 30px; */
  padding: 0;
  width: 50%;
}

.contact-body-input-right h1{
  padding-bottom: 2px;
}

.contact-body {
  padding: 100px 70px;
}
}

/* ---------------- contact Footer -------------- */

.contact-footer{
  color: #fff;
  background-color: var(--mainColor);
  margin-top: 50px;
  padding: 50px 25px;
}

.contact-footer-logo img{
  width: 150px;
}

.contact-us{
  padding: 15px 0;
}

.contact-us-icon{
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px 0;
}

@media only screen and (min-width:968px){

  .contact-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 17%;
  }

  .contact-footer-service{
    padding: 0;
  }

  .contact-footer-logo{
    margin-top: 40px;
  }

  .contact-footer-copywrite{
    height: max-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 17px 0;
    gap: 50px;
  }
}