

.about-header-container{
    padding-top: 100px;
    padding-bottom: 100px;
}

.about_header_text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 5%; */
}

.about_header_text h1{
    color: var(--mainColor);
    font-size: 35px;
    line-height: 1.3;
    /* letter-spacing: 3px; */
    font-weight: 600;
}

.about_header_text h3{
    color: var(--mainColor);
    font-size: 20px;
    line-height: 1.3;
    letter-spacing: 0.2px;
    word-spacing: .1px;
    font-weight: 300;
    text-align: justify;
    padding: 20px 10px;
}

.about_header_image{
    width: 100%;
}

.image_container{
    position: relative;
    margin: 0 auto;
}

.image-wrapper-text{
    position: absolute;
    color: white;
    left: 0;
    bottom: 0px;
}

.image-wrapper-text h2{
text-transform: uppercase;
color: #fff;
font-weight: 600;
text-align: left;
font-size: 34px;
/* margin-bottom: 5px; */
margin-left: 10px;
}

.image-wrapper-text button{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 400;
    text-align: left;
    font-size: 21px;
    margin-bottom: 15px;
    margin-left: 10px;
    }

    .overlayed:hover{
        background: linear-gradient(rgba(32, 105, 137, 0.7), rgba(0, 0, 0, 0.7));
        
    }

    @media only screen and (max-width:600px){
        .overlayed{
            background: linear-gradient(rgba(32, 105, 137, 0.7), rgba(0, 0, 0, 0.7));
        }
    }

    @media only screen and (min-width:768px){
        
        .about_header_text h1{
            margin-top: 50px;
            font-size: 75px;
            line-height: 1.3;
            letter-spacing: 3px;
            font-weight: 600;
        }

        .about_header_text h3{
            font-size: 20px;
            line-height: 1.3;
            letter-spacing: .5px;
            /* word-spacing: .1px; */
            font-weight: 300;
            text-align: justify;
            padding: 20px 150px;
            text-transform: uppercase;
        }

        .about_header_image{
            
            width: 100%;
            padding: 50px 100px;
        }
        
        .image_container{
            /* position: relative; */
            /* margin: 0 auto; */
            height: 350px;
            width: 350px;
        }

        .image_container:hover{
           /* background-color: linear-gradient(rgba(3, 6, 18, 0.7), rgba(8, 3, 3, 0.7)); */
        }
        
        
    }