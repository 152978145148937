


.serviceMain{
    
background-image: linear-gradient(rgba(13, 22, 59, 0.7), rgba(32, 30, 30, 0.7)), url('/public/images/background.png');
 padding: 5%;   
 background-repeat: no-repeat;
 background-size: cover;
 background-position: center;
}

.serviceTitle{
    text-align: center;
    padding: 10%;
    font-size: 35px;
    font-weight: 700;
    color: white;
    text-transform: uppercase;
    line-height: 1em;
    letter-spacing: 3px;
}

.serviceImageDiv{
    position: relative;
    border: 4px solid #619FDC;
    padding: 4px;
}

.serviceImageDiv:hover{
    cursor: pointer;
}

.serviceImageDiv img{
    width: 370px;
    height: 250px;
    object-fit: cover;
}

.serviceItemText{
    width: 100%;
    /* background-color: var(--mainColor); */
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 5%;
    z-index: 2;
}



.serviceItemText h1{
    color: white;
   font-weight: 700;
   line-height: 10px;
   font-size: 16px;
   
}


 @media only screen and (min-width: 768px) {
    .serviceTitle{
        font-size: 75px;
    padding: 25px 0;
    font-weight: 800;
    color: white;
    /* text-decoration: underline; */
    margin: 20px 0 45px 0;
    }

    .serviceItemText{
        width: 100%;
        /* background-color: var(--mainColor); */
    }
    .serviceItemText h1{
        font-weight: 700;
        line-height: 25px;
        font-size: 30px;
     }

    .serviceTitle h1{
        padding: 2px 0;
    }
    .serviceItemText:hover{
        background-color: var(--mainColor);
    
    }
    
    .serviceMain{
    
     padding-bottom: 150px;
        }
 }